@use "src/constants.scss" as *;

.content_wrapper {
  background-color: $light-gray;
  flex-grow: 1;

  display: flex;
  justify-content: center;
}

.content_background {
  flex-grow: 1;

  margin: 16px 16px;

  background-color: white;
  max-width: 1096px;

  padding: 16px;
}
.beer_info {
  display: flex;
  flex-flow: row;
}

.go_back_button {
  font-size: 20px;
  text-decoration: none;
  color: #a8bbf9;
}

.beer_card {
  margin: 8px 16px;
  font-family: Consolas, monaco, monospace;
}

h1 {
  margin: 0;

  .favorite_star {
    font-size: 30px;
  }
}

h2 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}
// Original "Beer" Theme
$tan: #b68d40;
$cream: #f4ebd0;
$charcoal: #122620;
$gold: #d6ad60;

// New "Grayish" Theme
$light-gray: #EEEDE7;
$gray: #B9B7BD;
$dark-gray: #868B8E;
$seashell: #E7D2CC;